<template>
  <div class="firmDynamic">
    <!-- <headerComponent /> -->

    <img :src="require('@/assets/bgc5.jpg')" alt="" class="banner" />

    <div class="tab">
      <div
        class="tab_title"
        :class="tabIndex == index ? 'isActive' : ''"
        @click="
          tabIndex = index;
          getList(item.id);
        "
        v-for="(item, index) in tabList"
        :key="index"
      >
        <img :src="item.img" alt="" />

        {{ item.name }}
      </div>
    </div>

    <div
      class="item_content"
      @click="
        $router.push({
          path: '/firmDetails',
          query: {
            id: item.id,
          },
        })
      "
      v-for="item in list"
      :key="item.id"
    >
      <div class="left">
        <div class="date">{{ item.createTime }}</div>

        <div class="title">
          {{ item.title }}
        </div>

        <div class="text">
          {{ item.content }}
        </div>

        <!-- <div class="more">更多></div> -->
      </div>
      <div class="right">
        <img :src="$store.state.onlineBasePath + item.imgUrl" alt="" />
      </div>
    </div>

    <div class="kong" v-if="total == 0">
      <img :src="require('assets/images/kong.png')" alt="" />
    </div>

    <div class="commonFooter_box">
      <commonFooter />
    </div>
  </div>
</template>

<script>
//例如：import  from '';
import { findEnterpriseDynamics, findDynamicInfoList } from "api/common.js";
import headerComponent from "./headerComponent";
import commonFooter from "components/footer/commonFooter.vue";
import qs from "qs";
export default {
  name: "firmDynamic",
  //import引入的组件需要注入到对象中才能使用
  components: {
    // headerComponent,
    commonFooter,
  },
  data() {
    //这里存放数据
    return {
      tabIndex: 0,
      tabList: [
        {
          name: "公司动态",
          img: require("assets/images/web-version.png"),
          id: "",
        },
        {
          name: "行业资讯",
          img: require("assets/images/jishu-version.png"),
          id: "",
        },
        {
          name: "常见问题",
          img: require("assets/images/wps-version.png"),
          id: "",
        },
      ],
      list: [],
      total: 0,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getTabList() {
      const res = await findEnterpriseDynamics();

      if (res.code == 200) {
        res.data.forEach((item, index) => {
          index == 0 ? this.getList(item.id) : "";
          this.tabList[index].id = item.id;
        });
      }
    },

    async getList(id) {
      const data = {
        parentId: id,
      };

      const res = await findDynamicInfoList(qs.stringify(data));

      if (res.code == 200) {
        this.list = res.data.data;
        this.total = res.data.total;
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getTabList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang="scss" scoped>
.firmDynamic {
  position: absolute;
  background: #f5f5f2;
  top: 0px;
  left: 0;
  width: 100%;

  .banner {
    width: 100%;
    height: 420px;
  }

  .tab {
    width: 1526px;
    display: flex;
    align-items: center;
    background-color: #fff;
    margin: 0 auto;
    height: 80px;
    border-radius: 5px;
    transform: translateY(-40px);
    .tab_title {
      cursor: pointer;
      width: 33%;
      height: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      img {
        width: 26px;
        margin-right: 18px;
      }
    }

    .isActive {
      // color: #ff6808;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        width: 50px;
        height: 2px;
        background-color: #ff6900;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .item_content {
    width: 1526px;
    cursor: pointer;
    margin: 0 auto 20px;
    background-color: #fff;
    border-radius: 5px;
    padding: 30px 70px;
    box-sizing: border-box;
    justify-content: space-between;
    display: flex;
    align-items: center;
    .left {
      .date {
        color: #999;
      }
      .title {
        margin: 10px 0;
        font-size: 20px;
        font-weight: bold;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
      .text {
        color: #999;
        line-height: 1.8;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
      .more {
        cursor: pointer;
        margin-top: 10px;
        color: #2177c7;
      }
    }
    .right {
      margin-left: 40px;
      img {
        width: 220px;
        height: 140px;
      }
    }
  }

  .kong {
    width: 1526px;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin: 50px 0;
    }
  }

  .commonFooter_box {
    margin-top: 25px;
  }
}
</style>
