<template>
  <div class="headerComponent">
    <div class="header" :class="{ open: isShowDownMenu }">
      <img
        @click="navClick('/indexPage')"
        class="logo"
        :src="
          isShowDownMenu
            ? require('assets/images/logowit1.png')
            : require('assets/images/logowit.png')
        "
        alt=""
      />
      <div class="nav">
        <div
          class="nav-item"
          v-for="(item, i) in navList"
          @click="navClick(item.url)"
          :class="item.children ? 'showItemInfo' : ''"
          @mouseover="handleMouseOver(item)"
          :key="i"
        >
          {{ item.title }}
          <i v-if="item.children" class="el-icon-arrow-down"></i>

          <div class="downMenu">
            <div class="sidebarList">
              <div
                class="list_item"
                @click="infoClick(item1.labelId)"
                v-for="item1 in sidebarList[index].children"
                :key="item1.labelId"
              >
                {{ item1.conName }}
              </div>

              <div class="perch_item" v-for="item in 4" :key="item"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="userInfo">
        <div class="vipServer" @click="vipClick">
          <img :src="require('assets/images/newTweet/vip2.png')" alt="" />
          会员服务
        </div>
        <div class="user">
          <img
            :src="
              $store.state.user_info.headImg ||
              require('@/assets/images/user.png')
            "
            alt=""
          />
          <span class="user-name">{{
            $store.state.user_info.nickName || '请登录/注册'
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//例如：import  from '';
import { getlibrarylist } from 'api/dropDownList'
import qs from 'qs'
export default {
  name: 'headerComponent',
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      isShowDownMenu: false,
      index: 0,
      navList: [
        // {
        //   title: 'AI创作平台',
        //   url: 'http://work.writemall.com/',
        // },
        {
          title: '专业服务',
          url: '/professional',
          children: 1,
        },
        {
          title: '专业文库',
          url: '/infoCompileIndex',
          children: 1,
        },
        {
          title: '优质模板',
          url: '/templatetHtml',
          children: 1,
        },
        {
          title: '闪亮金句',
          url: '/goldenSentence/goldenSentenceIndex',
          children: 1,
        },

        {
          title: '软件下载',
          url: '/downloadPage',
        },
        {
          title: '企业动态',
          url: '/firmDynamic',
        },
        {
          title: '合作咨询',
          url: '/cooperationPage',
        },
        // {
        //   title: '商城服务',
        //   url: '/shoppingMall',
        // },
        // {
        //   title: "加入我们",
        //   url: "/join_about",
        // },
      ],

      sidebarList: [
        {
          name: '文库',
          index: '1',
          children: [],
        },
        {
          name: '金句',
          index: '2',
          children: [],
        },
        {
          name: '模板',
          index: '3',
          children: [],
        },
      ],
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    vipClick() {
      if (this.$store.state.user_info.memberlevel == 1) {
        this.$router.push('/details/1/1')
      }
      if (
        this.$store.state.user_info.memberlevel == 2 ||
        this.$store.state.user_info.memberlevel == 3
      ) {
        this.$router.push('/details/1/2')
      }
    },

    infoClick(labelId) {
      // return;
      if (this.index === 0) {
        this.$bus.$emit('changeNavIndex', '文库')
        this.$router.push({
          name: 'InfoCompileIndex',
          query: {
            labelId,
          },
        })
      }
      if (this.index === 1) {
        this.$bus.$emit('changeNavIndex', '模板')
        this.$router.push({
          name: 'templatetHtml',
          query: {
            labelId,
          },
        })
      }
      if (this.index === 2) {
        this.$bus.$emit('changeNavIndex', '金句')
        this.$router.push({
          name: 'GoldenSentenceIndex',
          query: {
            labelId,
          },
        })
      }
    },
    handleMouseOver(item) {
      if (item.title == '专业文库') {
        this.index = 0
      }
      if (item.title == '优质模板') {
        this.index = 1
      }
      if (item.title == '闪亮金句') {
        this.index = 2
      }

      // if (item.children) {
      //   this.isShowDownMenu = true;
      // } else {
      //   this.isShowDownMenu = false;
      // }
    },

    // 获取侧边栏数据
    async getSidebarList(parentId, index) {
      const data = {
        labelId: '',
        parentId,
      }

      const res = await getlibrarylist(qs.stringify(data))

      if (res.code == 200) {
        this.sidebarList[index].children = res.data
      } else {
        this.$message({
          message: res.message,
        })
      }
    },

    navClick(url) {
      if (url == 'http://work.writemall.com/') {
        window.open(url)
        return
      }
      this.$router.push(url)
    },

    handleMouseOut() {
      this.isShowDownMenu = false
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getSidebarList(7, 0)
    this.getSidebarList(3, 1)
    this.getSidebarList(2, 2)
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
}
</script>
<style lang="scss" scoped>
.headerComponent {
  .header {
    position: absolute;
    top: 0;
    z-index: 11;
    width: 100%;
    height: 65px;
    align-items: center;
    display: flex;
    padding: 0 160px;
    box-sizing: border-box;
    .logo {
      cursor: pointer;
    }

    .downMenu {
      display: none;
      background-color: rgba(255, 255, 255, 1);
      transition: all 0.3s;
      position: absolute;
      z-index: 11;
      bottom: 0;
      width: 700px;
      border-radius: 5px;
      transform: translateY(100%);

      .sidebarList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .perch_item {
          width: 25%;
        }
        .list_item {
          line-height: 14px;
          font-size: 14px;
          width: 25%;
          text-align: center;
          font-weight: normal;
          margin: 10px 0;
          color: #000;

          &:hover {
            color: #ff6900;
          }
        }
      }
    }
    .isShow {
      // height: 213px;
      height: auto;
    }
    .nav {
      flex: 1;
      display: flex;
      height: 100%;
      align-items: center;
      .nav-item {
        height: 100%;
        line-height: 65px;
        font-size: 16px;
        color: #fffefe;
        margin-right: 59px;
        cursor: pointer;
        position: relative;
        &:hover {
          // color: #ff6500;
          font-weight: bold;

          font-size: 18px;
        }
      }

      .showItemInfo {
        &:hover {
          .downMenu {
            display: block;
          }
        }
      }
    }
    .userInfo {
      display: flex;
      align-items: center;
      .vipServer {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 13px;
        margin-right: 27px;
        color: #fffefe;
        img {
          margin-right: 8px;
          width: 16px;
          height: 16px;
        }
      }
      .user {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #fffefe;
        img {
          margin-right: 9px;
          width: 37px;
          height: 37px;
          border-radius: 50%;
        }
      }
    }
    > img {
      width: 108px;
      height: 37px;
      margin-right: 74px;
    }
  }
  .open {
    background-color: rgba(255, 255, 255, 0.9);
    > .nav {
      .nav-item {
        color: #333;
        cursor: pointer;
      }
    }
    .userInfo {
      .vipServer {
        color: #333;
      }
      .user {
        color: #333;
      }
    }
  }
}
</style>
